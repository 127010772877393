import React from "react";
import { SimpleTitle } from "../../title";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import IconStorage from "@material-ui/icons/SaveOutlined";
import IconNetwork from "@material-ui/icons/SwapVert";
import IconMemory from "@material-ui/icons/Memory";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formControlLabel: {
    fontSize: ".75rem",
  },
  formControlLabelChecked: {
    color: theme.palette.primary.main,
  },
  slider: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sliderBar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
}));

const testData1 = [
  { label: "00:00", value: 12.52 },
  { label: "00:05", value: 16.52 },
  { label: "00:10", value: 8.46 },
];

//只负责展示
export function Resource(props) {
  const { nodeLimit, setNodeLimit, cpuCores = 0 } = props;
  const cpuList = [0, 1, 2, 4, 8, 16, 32, 64]
    .filter((v) => v <= cpuCores)
    .map((v) => ({ value: v, label: `${v || "默认"}` }));
  const setMode = (mode) => {
    // console.log("new mode", mode, typeof mode);
    switch (mode) {
      case "1":
        setNodeLimit({
          ...nodeLimit,
          storage: 100,
          bandwidth: 100,
          memory: 100,
        });
        break;
      case "2":
        setNodeLimit({
          ...nodeLimit,
          storage: 80,
          bandwidth: 80,
          memory: 80,
        });
        break;
      case "3":
        setNodeLimit({
          ...nodeLimit,
          storage: 60,
          bandwidth: 60,
          memory: 60,
        });
        break;
      default:
        break;
    }
  };
  const classes = useStyles();
  let mode = "0";
  const { storage, bandwidth, memory, cpu } = nodeLimit;
  if (storage === bandwidth && bandwidth === memory) {
    switch (storage) {
      case 100:
        mode = "1";
        break;
      case 80:
        mode = "2";
        break;
      case 60:
        mode = "3";
        break;
      default:
        break;
    }
  }
  const handleSliderChange = (name) => (event, newValue) => {
    setNodeLimit({
      ...nodeLimit,
      [name]: newValue,
    });
  };
  return (
    <div>
      <SimpleTitle>资源分配</SimpleTitle>
      <RadioGroup
        className={classes.radioGroup}
        row
        name="gender1"
        value={mode}
        onChange={(event) => setMode(event.target.value)}
      >
        <FormControlLabel
          classes={{
            label: clsx(
              classes.formControlLabel,
              mode === "1" && classes.formControlLabelChecked
            ),
          }}
          value="1"
          control={<Radio color="primary" />}
          label="高收益模式"
        />
        <FormControlLabel
          classes={{
            label: clsx(
              classes.formControlLabel,
              mode === "2" && classes.formControlLabelChecked
            ),
          }}
          value="2"
          control={<Radio color="primary" />}
          label="保守模式"
        />
        <FormControlLabel
          classes={{
            label: clsx(
              classes.formControlLabel,
              mode === "3" && classes.formControlLabelChecked
            ),
          }}
          value="3"
          control={<Radio color="primary" />}
          label="低收益模式"
        />
      </RadioGroup>
      <div className={classes.slider}>
        <Typography variant="caption">
          <IconStorage className={classes.verticalMiddle} fontSize="small" />{" "}
          分配存储：{storage == 100 ? "默认" : storage + "%"}
        </Typography>
        <div className={classes.sliderBar}>
          <Slider
            min={60}
            max={100}
            step={1}
            value={storage}
            onChange={handleSliderChange("storage")}
          />
        </div>
      </div>

      <div className={classes.slider}>
        <Typography variant="caption">
          <IconNetwork className={classes.verticalMiddle} fontSize="small" />{" "}
          分配带宽：{bandwidth == 100 ? "默认" : bandwidth + "%"}
        </Typography>
        <div className={classes.sliderBar}>
          <Slider
            min={60}
            max={100}
            step={1}
            value={bandwidth}
            onChange={handleSliderChange("bandwidth")}
          />
        </div>
      </div>

      <div className={classes.slider}>
        <Typography variant="caption">
          <embed
            className={classes.verticalMiddle}
            src="/memory.svg"
            type="image/svg+xml"
            width="20"
            height="20"
            style={{ marginRight: "0px" }}
          />{" "}
          分配内存：{memory == 100 ? "默认" : memory + "%"}
        </Typography>
        <div className={classes.sliderBar}>
          <Slider
            min={60}
            max={100}
            step={1}
            value={memory}
            onChange={handleSliderChange("memory")}
          />
        </div>
      </div>

      <div className={classes.slider}>
        <Typography variant="caption">
          <IconMemory className={classes.verticalMiddle} fontSize="small" />{" "}
          CPU核数：{cpu == 0 ? "默认" : cpu}
        </Typography>
        <div className={classes.sliderBar}>
          <Slider
            min={0}
            max={cpuList[cpuList.length - 1].value}
            step={null}
            marks={cpuList}
            value={cpu}
            onChange={handleSliderChange("cpu")}
          />
        </div>
      </div>
    </div>
  );
}
