import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, loginQuery } from "../../../modules/auth/actions";
import LoginForm from "./loginForm";
import { messageError } from "../../../modules/utils/actions";

class LoginBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      formData: {},
    };
  }

  onFormChange(fields) {
    // console.log("onFormChange", fields);
    this.setState({
      formData: {
        ...this.state.formData,
        ...fields,
      },
    });
  }

  onSubmit() {
    this.props.onSubmit(this.state.formData);
  }

  getArgs() {
    let args = {},
      query = this.props.location.search,
      search = decodeURIComponent(query.substring(1)),
      reg = /(?:([^&]+)=([^&]+))/g,
      match = reg.exec(search);
    while (match) {
      args[match[1]] = match[2];
      match = reg.exec(search);
    }
    return args;
  }

  render() {
    // console.log("render main formData", this.state.formData);
    const loginFormParam = {
      onSubmit: this.onSubmit,
      onChange: (fields) => this.onFormChange(fields),
      loading: this.props.loginLoading,
      ...this.state.formData,
    };
    return this.props.isAuthenticated ? (
      <Redirect
        to={
          this.props.location.from ? this.props.location.from.pathname : "/home"
        }
      />
    ) : (
      <LoginForm {...loginFormParam} />
    );
  }

  componentDidMount() {
    let query = (this.args = this.getArgs());
    if (query.token && query.user_id && query.username) {
      this.props.onRequire({
        token: query.token,
        user_id: query.user_id,
        username: query.username,
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loginLoading: state.auth.loginStatus === "loading",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      let temp = {};
      for (let k in data) {
        temp[k] = data[k].value;
      }
      // console.log("submit data username", temp.username);
      // console.log("submit data password", temp.password);
      dispatch(
        login({
          data: temp,
          error: (error) => {
            dispatch(messageError(error.message));
          },
        })
      );
    },
    onRequire: (data) => {
      dispatch(loginQuery(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox);
