import React, { useCallback, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    padding: "2px 4px",
    backgroundColor: "transparent",
    transition: "border-color .2s",
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: "0.9rem",
  },
  iconButton: {
    padding: 6,
  },
}));

export default function CustomizedInputBase(props) {
  const { placeholder, onChange, onSearch, value } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [focus, setFocus] = useState(false);
  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      onSearch && onSearch(value);
    }
  };

  const handleSearch = () => {
    onSearch && onSearch(value);
  };

  const handleClear = useCallback(() => {
    onChange && onChange("");
  }, [onChange]);

  return (
    <Paper
      className={classes.root}
      elevation={1}
      variant="outlined"
      style={{ borderColor: focus ? theme.palette.primary.main : null }}
    >
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{ "aria-label": placeholder, maxLength: 1024 }}
        onKeyUp={onKeyUp}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="clear"
        size="small"
        onClick={handleClear}
        style={{
          display: focus && value !== "" ? "block" : "none",
        }}
        onMouseDown={(e) => e.preventDefault()}
        onMouseUp={(e) => e.preventDefault()}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        size="small"
        onClick={handleSearch}
      >
        <SearchIcon fontSize="small" />
      </IconButton>
    </Paper>
  );
}
