import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Title } from "../../modules/title";
import Table from "../../modules/table";
import { AdvancedNodeListItem } from "../../modules/listItem";
import {
  fetchAdvancedNodes,
  getAdvancedNodeMachineError,
  getCPError,
  getErrorType,
} from "../../modules/bc/actions";
import { cashToRMB } from "../../modules/utils/functions";
import { AdvancedNodeDetail } from "../../modules/nodeDetail";
import { fetchNotifications, messageOk } from "../../modules/utils/actions";
import EasyAnnounce from "../../modules/easyAnnounce";
import { getPreferences, updatePreferences } from "../../modules/user/actions";
import { withRouter } from "react-router-dom";
import { TableSortWrapper } from "./MyMineNodes.js";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

function MyReward(props) {
  const classes = useStyles();

  const { myNodes, myNodesLoading, onRequire, notifications } = props;

  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [myAdvancedSort, setmyAdvancedSort] = useState({
    key: "rewardYesterday",
    sort: "ASC",
  });
  const [myAdvancedOriginalList, setMyAdvancedOriginalList] = useState([]);
  const [myAdvancedNodesList, setMyAdvancedNodesList] = useState([]);

  useEffect(() => {
    onRequire();
  }, []);

  useEffect(() => {
    const myAdvancedValue =
      props.preferences?.[
        `${props?.location?.pathname}+myAdvancedNodesTable+rowsPerPage`
      ];
    myAdvancedValue && setRowsPerPage(Number(myAdvancedValue));
    // 排序
    const myAdvancedSortValue =
      props.preferences?.[
        `${props?.location?.pathname}+myAdvancedNodesTable+sort`
      ];
    if (myAdvancedSortValue) {
      try {
        const m = JSON.parse(myAdvancedSortValue);
        setmyAdvancedSort(m);
      } catch (error) {
        console.error("parse myNodeSortValue error:", error);
      }
    }
  }, [props.preferences, props.location]);

  useEffect(() => {
    myNodes &&
      Array.isArray(myNodes) &&
      setMyAdvancedOriginalList(
        myNodes.map((v) => ({
          ...v,
          id: v.machine_id,
          name: v.name + " （" + v.ip + "）",
          online: v.online,
          approved: true, //目前不考虑是否验收通过
          rewardYesterday: cashToRMB(v.yesterday_reward),
          totalReward: cashToRMB(v.total_reward),
          waiting: (v.yesterday_reward || 0) < 0,
          userName: v.name + " （" + v.ip + "）",
        }))
      );
  }, [myNodes]);

  const myAdvancedNodesTableParam = {
    list: myAdvancedNodesList,
    loading: myNodesLoading,
    render: (v, i) => (
      <AdvancedNodeListItem
        nodeInfo={v}
        key={i}
        onClick={() => setOpenedNodeInfo(v)}
      />
    ),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (value) => {
      const key = `${props?.location?.pathname}+myAdvancedNodesTable+rowsPerPage`;
      setRowsPerPage(value);
      props.updatePreferences({
        preference_key: key,
        preferences: JSON.stringify(value),
      });
    },
  };

  const handleUpdateList = (list) => {
    setMyAdvancedNodesList(list);
  };

  const handleUpdateSort = (sortObj) => {
    if (
      myAdvancedSort &&
      myAdvancedSort.key === sortObj.key &&
      myAdvancedSort.sort === sortObj.sort
    ) {
      return;
    }
    setmyAdvancedSort(sortObj);
    props.updatePreferences({
      preference_key: `${props?.location?.pathname}+myAdvancedNodesTable+sort`,
      preferences: JSON.stringify(sortObj),
    });
  };

  return (
    <div>
      <Title>优享节点</Title>
      <EasyAnnounce notifications={notifications} />
      {myAdvancedOriginalList && myAdvancedOriginalList.length ? (
        <TableSortWrapper
          list={myAdvancedOriginalList}
          defaultSort={myAdvancedSort}
          actions={["rewardYesterday", "totalReward", "userName"]}
          filterActions={["online"]}
          updateList={handleUpdateList}
          updateSort={handleUpdateSort}
        />
      ) : null}
      <Table {...myAdvancedNodesTableParam} />
      <Title>添加节点</Title>
      <Typography className={classes.paragraph} variant="body1">
        需要添加优享节点，请与工作人员联系，微信：Cheedoong
      </Typography>
      <AdvancedNodeDetail
        open={openedNodeInfo != null}
        onClose={() => setOpenedNodeInfo(null)}
        nodeInfo={openedNodeInfo}
        {...props}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorTypeMap: state.bc.errorTypeMap || {},
    CPErrorMap: state.bc.cpErrorMap || {},
    machineErrorMap: state.bc.machineErrorMap || {},
    myNodes: state.bc.advancedNodes || [],
    myNodesLoading: state.bc.getAdvancedNodesStatus === "loading",
    notifications: (state.utils.notifications || {})["优享节点"] || [],
    preferences: state.user.preferences,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getPreferences());
      dispatch(fetchAdvancedNodes());
      dispatch(fetchNotifications("优享节点"));
      dispatch(getCPError());
      dispatch(getErrorType());
    },

    getMachineError: (data) => {
      dispatch(
        getAdvancedNodeMachineError(
          {
            success: () => {
              dispatch(messageOk("请求成功！"));
            },
          },
          data
        )
      );
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    updatePreferences: (data) => {
      dispatch(
        updatePreferences(data, {
          success: () => {
            // dispatch(getPreferences());
          },
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyReward));
