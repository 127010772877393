import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";

import blue from "@material-ui/core/colors/blue";
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  spanButton: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    display: "inline-block",
    color: blue[500],
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  filterButton: {
    verticalAlign: "middle",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  tableCell: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

/*
 * list:[] 必须
 * columns:[column] 必须
 * column: {
 *   title: string,
 *   key: string,
 *   render: (value, index)=>{}
 * }
 * selectedRows: [number]
 * onSelectChange: ([number])=>{}
 * onEdit: (row)=>{}
 * onDelete: (row)=>{}
 * loading: bool
 */

class View extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleToggleFilterAll = this.handleToggleFilterAll.bind(this);
    let filterOption = {};
    props.columns.forEach((v) => {
      if (v.filter) {
        let options = [];
        if (v.filterOptions) {
          options = [...v.filterOptions];
        } else {
          let values = [];
          props.list.forEach((item) => {
            if (values.indexOf(item[v.key]) < 0) {
              values.push(item[v.key]);
            }
          });
          options = values.map((value) => ({
            name: value,
            onFilter: (v) => v === value,
          }));
        }

        filterOption[v.key] = options;
      }
    });
    // console.log("filterOption", filterOption);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      filterOption: filterOption,
      filterState: filterOption,
      filterDialogOpenKey: "",
    };
  }
  handleChangePage(event, newPage) {
    this.setState({
      page: newPage,
    });
    if (this.props.onPageChange) {
      this.props.onPageChange(newPage);
    }
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value,
    });
    if (this.props.onRowsPerPageChange) {
      this.props.onRowsPerPageChange(event.target.value);
    }
  }

  openFilterDialog = (key) => () => {
    this.setState({
      filterDialogOpenKey: key,
    });
  };

  closeFilterDialog() {
    this.setState({
      filterDialogOpenKey: "",
    });
  }

  handleToggleFilter = (key, name) => () => {
    const checked = (this.state.filterState[key] || []).map(
      (option) => option.name
    );
    const currentIndex = checked.indexOf(name);
    const newChecked = [...(this.state.filterState[key] || [])];
    const optionsName = this.state.filterOption[key].map(
      (option) => option.name
    );

    if (currentIndex === -1) {
      newChecked.push({
        ...this.state.filterOption[key][optionsName.indexOf(name)],
      });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      filterState: {
        ...this.state.filterState,
        [key]: newChecked,
      },
    });
  };
  handleToggleFilterAll = (key) => () => {
    const checked = this.state.filterState[key] || [];
    if (checked.length === this.state.filterOption[key].length) {
      this.setState({
        filterState: {
          ...this.state.filterState,
          [key]: [],
        },
      });
    } else {
      this.setState({
        filterState: {
          ...this.state.filterState,
          [key]: [...this.state.filterOption[key]],
        },
      });
    }
  };

  handleCheck = (index) => (event, checked) => {
    var selectRows = this.props.selectedRows;
    if (checked) {
      selectRows.push(index);
    } else {
      selectRows.splice(selectRows.indexOf(index), 1);
    }

    selectRows.sort((a, b) => a - b);
    this.props.onSelectChange(selectRows);
  };
  handleCheckAll = (event, checked) => {
    var selectRows = this.props.selectedRows;
    var start = this.state.page * this.state.rowsPerPage;
    var end = Math.min(
      (this.state.page + 1) * this.state.rowsPerPage,
      this.props.list.length
    );
    if (checked) {
      for (let i = start; i < end; i++) selectRows.push(i);
      selectRows = selectRows.filter(
        (number, index, arr) => arr.indexOf(number) === index
      );
    } else {
      selectRows = selectRows.filter(
        (number) => number < start || number >= end
      );
    }
    selectRows.sort((a, b) => a - b);
    this.props.onSelectChange(selectRows);
  };

  render() {
    const {
      classes,
      list,
      total,
      page,
      length,
      exportList,
      columns,
      selectedRows,
      onHide,
      onRecover,
      onDelete,
      onExport,
      onEdit,
      onDetail,
      onStatistics,
      onImportMACs,
      loading,
      fileName,
      render,
    } = this.props;
    // console.log("filterState", this.state.filterState);
    const filteredList = list.filter((row) => {
      let result = true;
      Object.keys(this.state.filterState).forEach((key) => {
        let passThisFilter = false;
        this.state.filterState[key].forEach((option) => {
          if (option.onFilter(row[key])) {
            passThisFilter = true;
          }
        });
        if (!passThisFilter) {
          result = false;
        }
      });
      return result;
    });
    // console.log("filteredList", filteredList);
    var checkBoxEnabled = !!selectedRows;
    var start = total ? 0 : this.state.page * this.state.rowsPerPage;
    var end = total
      ? Math.min(length, filteredList.length)
      : Math.min(
          (this.state.page + 1) * this.state.rowsPerPage,
          filteredList.length
        );
    var selectAll = true;
    if (checkBoxEnabled) {
      if (selectedRows.length < 1) {
        selectAll = false;
      } else {
        for (let i = start; i < end; i++) {
          if (selectedRows.indexOf(i) < 0) {
            selectAll = false;
            break;
          }
        }
      }
    }

    if (render) {
      return (
        <div>
          {list.slice(start, end).map((v, index) => render(v, index))}
          {loading ? <LinearProgress /> : null}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={total || filteredList.length || 0}
            rowsPerPage={total ? length : this.state.rowsPerPage}
            page={total ? page : this.state.page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage={"每页行数："}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to}/${count}`
            }
          />
        </div>
      );
    }

    const filterDialog = (key) => (
      <Dialog
        key={key}
        open={this.state.filterDialogOpenKey === key}
        onClose={this.closeFilterDialog}
      >
        <div>
          <List>
            <ListItem button dense onClick={this.handleToggleFilterAll(key)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    (this.state.filterState[key] || []).length ===
                    (this.state.filterOption[key] || []).length
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": `checkbox-list-label-全选` }}
                />
              </ListItemIcon>
              <ListItemText id={`checkbox-list-label-全选`} primary="全选" />
            </ListItem>
            {this.state.filterOption[key].map((row, index) => (
              <ListItem
                button
                dense
                onClick={this.handleToggleFilter(key, row.name)}
                key={index}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      (this.state.filterState[key] || [])
                        .map((option) => option.name)
                        .indexOf(row.name) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": `checkbox-list-label-${row.name}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`checkbox-list-label-${row.name}`}
                  primary={row.name}
                />
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    );

    const filterDialogs = [];
    columns.forEach((v) => {
      if (v.filter) {
        filterDialogs.push(filterDialog(v.key));
      }
    });

    return (
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {checkBoxEnabled ? (
                <TableCell className={classes.tableCell} padding="checkbox">
                  <Checkbox
                    onChange={this.handleCheckAll}
                    checked={selectAll}
                  />
                </TableCell>
              ) : null}
              {columns.map((column, index) => (
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  key={index}
                >
                  {column.title || ""}
                  {column.filter && (
                    <FilterListIcon
                      className={classes.filterButton}
                      style={{ fontSize: "1.4em" }}
                      onClick={this.openFilterDialog(column.key)}
                    />
                  )}
                </TableCell>
              ))}
              {onDelete ||
              onEdit ||
              onDetail ||
              onStatistics ||
              onImportMACs ? (
                <TableCell className={classes.tableCell} align="center">
                  操作
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.slice(start, end).map((v, index) => (
              <TableRow key={index}>
                {checkBoxEnabled ? (
                  <TableCell className={classes.tableCell} padding="checkbox">
                    <Checkbox
                      onChange={this.handleCheck(start + index)}
                      checked={selectedRows.indexOf(start + index) >= 0}
                    />
                  </TableCell>
                ) : null}
                {columns.map((column, columnIndex) => (
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    key={columnIndex}
                  >
                    {column.render
                      ? column.render(
                          v[column.key],
                          start + index,
                          filteredList
                        )
                      : v[column.key]}
                  </TableCell>
                ))}
                {onDelete ||
                onEdit ||
                onDetail ||
                onStatistics ||
                onImportMACs ? (
                  <TableCell className={classes.tableCell} align="center">
                    {onDetail ? (
                      <span
                        className={classes.spanButton}
                        onClick={() => onDetail(v, start + index, filteredList)}
                      >
                        详情&ensp;
                      </span>
                    ) : null}
                    {onStatistics ? (
                      <span
                        className={classes.spanButton}
                        onClick={() =>
                          onStatistics(v, start + index, filteredList)
                        }
                      >
                        统计信息&ensp;
                      </span>
                    ) : null}
                    {onImportMACs ? (
                      <span
                        className={classes.spanButton}
                        onClick={() =>
                          onImportMACs(v, start + index, filteredList)
                        }
                      >
                        导入MAC&ensp;
                      </span>
                    ) : null}
                    {onExport ? (
                      <CSVLink data={onExport(v)[1]} filename={onExport(v)[0]}>
                        <span className={classes.spanButton}>导出&ensp;</span>
                      </CSVLink>
                    ) : null}
                    {onEdit ? (
                      <span
                        className={classes.spanButton}
                        onClick={() => onEdit(v, start + index, filteredList)}
                      >
                        编辑&ensp;
                      </span>
                    ) : null}
                    {onHide ? (
                      <span
                        className={classes.spanButton}
                        onClick={() => onHide(v, start + index, filteredList)}
                      >
                        屏蔽&ensp;
                      </span>
                    ) : null}
                    {onRecover ? (
                      <span
                        className={classes.spanButton}
                        onClick={() =>
                          onRecover(v, start + index, filteredList)
                        }
                      >
                        恢复&ensp;
                      </span>
                    ) : null}
                    {onDelete ? (
                      <span
                        className={classes.spanButton}
                        onClick={() => onDelete(v, start + index, filteredList)}
                      >
                        删除&ensp;
                      </span>
                    ) : null}
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading ? <LinearProgress /> : null}
        <div style={{ float: "left", margin: "5px 10px" }}>
          <CSVLink
            data={exportList || filteredList || ""}
            filename={fileName || "macs.csv"}
          >
            <Button disabled={!filteredList} color="primary">
              导出CSV
            </Button>
          </CSVLink>
        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={total || filteredList.length || 0}
          rowsPerPage={total ? length : this.state.rowsPerPage}
          page={total ? page : this.state.page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={"每页显示行数："}
          labelDisplayedRows={({ from, to, count }) =>
            `总数：${count}，当前：${from}-${to}`
          }
        />
        {filterDialogs}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.rowsPerPage) {
      this.setState({
        rowsPerPage: this.props.rowsPerPage,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    let filterOption = {};
    newProps.columns.forEach((v) => {
      if (v.filter) {
        var options = [];
        if (v.filterOptions) {
          options = [...v.filterOptions];
        } else {
          let values = [];
          newProps.list.forEach((item) => {
            if (values.indexOf(item[v.key]) < 0) {
              values.push(item[v.key]);
            }
          });
          options = values.map((value) => ({
            name: value,
            onFilter: (v) => v === value,
          }));
        }

        filterOption[v.key] = options;
      }
    });
    this.setState({
      filterOption: filterOption,
      filterState: filterOption,
    });
    if (newProps.rowsPerPage) {
      this.setState({
        rowsPerPage: newProps.rowsPerPage,
      });
    }
  }
}

View.defaultProps = {
  list: [],
  columns: [],
};

export default withStyles(styles)(View);
