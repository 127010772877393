const Status = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export default (
  state = {
    info: [],
    traffic: [],
    bandwidth: [],
    cache: [],
    mac: [],
    phone: "",
    totalNode: 10,
  },
  action
) => {
  switch (action.type) {
    case "PHONE/FETCH_SUCCESS": {
      const phone = action.result.mobile;
      return { ...state, phone: phone };
    }
    case "PHONE/FETCH_FAILURE": {
      const phone = action.error.msg;
      return { ...state, phone: phone };
    }
    case "GET/TOTAL_STARTED": {
      return { ...state };
    }
    case "GET/TOTAL_SUCCESS": {
      const total = action.result.total;
      return { ...state, totalNode: total };
    }
    case "GET/TOTAL_FAILURE": {
      return { ...state };
    }
    case "NODE/INFO_STARTED": {
      return { ...state, infoStatus: Status.LOADING };
    }
    case "NODE/INFO_SUCCESS": {
      let result = action.result;
      return { ...state, infoStatus: Status.SUCCESS, info: result };
    }
    case "NODE/INFO_FAILURE": {
      return { ...state, infoStatus: Status.FAILURE };
    }
    case "NODE/ONOFFSTAT_STARTED": {
      return { ...state, OnOffStatus: Status.LOADING };
    }
    case "NODE/ONOFFSTAT_SUCCESS": {
      let result = action.result;
      return { ...state, OnOffStatus: Status.SUCCESS, onOffStat: result };
    }
    case "NODE/ONOFFSTAT_FAILURE": {
      return { ...state, OnOffStatus: Status.FAILURE };
    }
    case "NODE/NICKNAME_STARTED": {
      return { ...state, nicknameStatus: Status.LOADING };
    }
    case "NODE/NICKNAME_SUCCESS": {
      // console.log(
      //   "set nickname success",
      //   action.data.mac,
      //   action.data.nickname,
      // );
      return {
        ...state,
        nicknameStatus: Status.SUCCESS,
        info: state.info.map((node) => {
          if (node.mac_addr == action.data.mac) {
            return { ...node, nickname: action.data.nickname };
          }
          return node;
        }),
      };
    }
    case "NODE/NICKNAME_FAILURE": {
      return { ...state, nicknameStatus: Status.FAILURE };
    }
    case "NODE/SEARCH_STARTED": {
      return { ...state, searchStatus: Status.LOADING };
    }
    case "NODE/SEARCH_SUCCESS": {
      let result = action.result;
      return { ...state, searchStatus: Status.SUCCESS, info: result };
    }
    case "NODE/SEARCH_FAILURE": {
      return { ...state, searchStatus: Status.FAILURE };
    }
    case "NODE/TRAFFIC_STARTED": {
      return { ...state, trafficStatus: Status.LOADING, traffic: [] };
    }
    case "NODE/TRAFFIC_SUCCESS": {
      let result = action.result;
      // let result = action.result[0].values
      return { ...state, trafficStatus: Status.SUCCESS, traffic: result };
    }
    case "NODE/TRAFFIC_FAILURE": {
      return { ...state, trafficStatus: Status.FAILURE };
    }
    case "NODE/BANDWIDTH_STARTED": {
      return { ...state, bandwidthStatus: Status.LOADING, bandwidth: [] };
    }
    case "NODE/BANDWIDTH_SUCCESS": {
      let result = action.result[0].values;
      return { ...state, bandwidthStatus: Status.SUCCESS, bandwidth: result };
    }
    case "NODE/BANDWIDTH_FAILURE": {
      return { ...state, bandwidthStatus: Status.FAILURE };
    }
    case "NODE/CACHE_STARTED": {
      return { ...state, cacheStatus: Status.LOADING };
    }
    case "NODE/CACHE_SUCCESS": {
      let result = action.result;
      return { ...state, cacheStatus: Status.SUCCESS, cache: result };
    }
    case "NODE/CACHE_FAILURE": {
      return { ...state, cacheStatus: Status.FAILURE };
    }
    case "NODE/MAC_STARTED": {
      return { ...state, macStatus: Status.LOADING, currentStep: 0 };
    }
    case "NODE/MAC_SUCCESS": {
      let result = action.result;
      if (result.mac_addrs.length < 1) {
        return {
          ...state,
          macStatus: Status.SUCCESS,
          mac: result.mac_addrs,
          currentStep: 0,
        };
      }
      return {
        ...state,
        macStatus: Status.SUCCESS,
        mac: result.mac_addrs,
        currentStep: 1,
      };
    }
    case "NODE/MAC_FAILURE": {
      return { ...state, macStatus: Status.FAILURE, currentStep: 0 };
    }
    case "NODE/CONFIG_STARTED": {
      return { ...state, configStatus: Status.LOADING };
    }
    case "NODE/CONFIG_SUCCESS": {
      let data = action.data;
      if (state.list != undefined) {
        let list = state.list.map((item) => {
          if (item.mac_addr === data.mac_addr) {
            return {
              ...item,
              open_mine: data.config.open_mine,
              upload_limit: data.config.upload_limit,
              storage_limit: data.config.storage_limit,
            };
          } else return item;
        });
        return { ...state, configStatus: Status.SUCCESS, list: list };
      }

      return { ...state, configStatus: Status.SUCCESS };
    }
    case "NODE/CONFIG_FAILURE": {
      return { ...state, configStatus: Status.FAILURE };
    }
    case "NODE/BIND_STARTED": {
      return { ...state, bindStatus: Status.LOADING };
    }
    case "NODE/BIND_SUCCESS": {
      return { ...state, bindStatus: Status.SUCCESS };
    }
    case "NODE/BIND_FAILURE": {
      return { ...state, bindStatus: Status.FAILURE };
    }
    case "NODE/BIND_STEP_PREV": {
      return {
        ...state,
        currentStep: state.currentStep ? state.currentStep - 1 : 0,
      };
    }
    case "NODE/BIND_STEP_NEXT": {
      return {
        ...state,
        currentStep: state.currentStep ? state.currentStep + 1 : 1,
      };
    }
    case "SHOW_MODAL": {
      return { ...state, modalShow: true };
    }
    case "HIDE_MODAL": {
      return { ...state, modalShow: false };
    }
    case "BASICNODE/TRAFFICTODAY_STARTED": {
      return {
        ...state,
        getBasicNodeTrafficTodayStatus: Status.LOADING,
        basicNodeTrafficToday: [],
      };
    }
    case "BASICNODE/TRAFFICTODAY_SUCCESS": {
      return {
        ...state,
        getBasicNodeTrafficTodayStatus: Status.SUCCESS,
        basicNodeTrafficToday: action.result,
      };
    }
    case "BASICNODE/TRAFFICTODAY_FAILURE": {
      return {
        ...state,
        getBasicNodeTrafficTodayStatus: Status.FAILURE,
        basicNodeTrafficToday: [],
      };
    }

    case "BASICNODE/CPUIOTODAY_STARTED": {
      return {
        ...state,
        getBasicNodeCPUIOTodayStatus: Status.LOADING,
        basicNodeCPUIOToday: null,
      };
    }
    case "BASICNODE/CPUIOTODAY_SUCCESS": {
      return {
        ...state,
        getBasicNodeCPUIOTodayStatus: Status.SUCCESS,
        basicNodeCPUIOToday: action.result,
      };
    }
    case "BASICNODE/CPUIOTODAY_FAILURE": {
      return {
        ...state,
        getBasicNodeCPUIOTodayStatus: Status.FAILURE,
        basicNodeCPUIOToday: null,
      };
    }

    case "BASICNODE/TRAFFICYESTERDAY_STARTED": {
      return {
        ...state,
        getBasicNodeTrafficYesterdayStatus: Status.LOADING,
        basicNodeTrafficYesterday: [],
      };
    }
    case "BASICNODE/TRAFFICYESTERDAY_SUCCESS": {
      return {
        ...state,
        getBasicNodeTrafficYesterdayStatus: Status.SUCCESS,
        basicNodeTrafficYesterday: action.result,
      };
    }
    case "BASICNODE/TRAFFICYESTERDAY_FAILURE": {
      return {
        ...state,
        getBasicNodeTrafficYesterdayStatus: Status.FAILURE,
        basicNodeTrafficYesterday: [],
      };
    }

    case "BASICNODE/CPUIOYESTERDAY_STARTED": {
      return {
        ...state,
        getBasicNodeCPUIOYesterdayStatus: Status.LOADING,
        basicNodeCPUIOYesterday: null,
      };
    }
    case "BASICNODE/CPUIOYESTERDAY_SUCCESS": {
      return {
        ...state,
        getBasicNodeCPUIOYesterdayStatus: Status.SUCCESS,
        basicNodeCPUIOYesterday: action.result,
      };
    }
    case "BASICNODE/CPUIOYESTERDAY_FAILURE": {
      return {
        ...state,
        getBasicNodeCPUIOYesterdayStatus: Status.FAILURE,
        basicNodeCPUIOYesterday: null,
      };
    }

    case "BASICNODE/CHARGINGBWS/LASTWEEK_STARTED": {
      return {
        ...state,
        getBasicNodeChargingBWsLastWeekStatus: Status.LOADING,
        basicNodeChargingBWsLastWeek: [],
      };
    }
    case "BASICNODE/CHARGINGBWS/LASTWEEK_SUCCESS": {
      return {
        ...state,
        getBasicNodeChargingBWsLastWeekStatus: Status.SUCCESS,
        basicNodeChargingBWsLastWeek: action.result,
      };
    }
    case "BASICNODE/CHARGINGBWS/LASTWEEK_FAILURE": {
      return {
        ...state,
        getBasicNodeChargingBWsLastWeekStatus: Status.FAILURE,
        basicNodeChargingBWsLastWeek: [],
      };
    }

    case "BASICNODE/CHARGINGBWS/LASTMONTH_STARTED": {
      return {
        ...state,
        getBasicNodeChargingBWsLastMonthStatus: Status.LOADING,
        basicNodeChargingBWsLastMonth: [],
      };
    }
    case "BASICNODE/CHARGINGBWS/LASTMONTH_SUCCESS": {
      return {
        ...state,
        getBasicNodeChargingBWsLastMonthStatus: Status.SUCCESS,
        basicNodeChargingBWsLastMonth: action.result,
      };
    }
    case "BASICNODE/CHARGINGBWS/LASTMONTH_FAILURE": {
      return {
        ...state,
        getBasicNodeChargingBWsLastMonthStatus: Status.FAILURE,
        basicNodeChargingBWsLastMonth: [],
      };
    }
    case "BASICNODE/INVITE/NODES_STARTED": {
      return {
        ...state,
        getInviterNodesStatus: Status.LOADING,
        inviterNodes: [],
      };
    }
    case "BASICNODE/INVITE/NODES_SUCCESS": {
      return {
        ...state,
        getInviterNodesStatus: Status.SUCCESS,
        inviterNodes: action.result,
      };
    }
    case "BASICNODE/INVITE/NODES_FAILURE": {
      return { ...state, getInviterNodesStatus: Status.FAILURE };
    }
    default: {
      return state;
    }
  }
};
