import React from "react";
import { connect } from "react-redux";
import {
  closeMessageError,
  closeMessageInfo,
  closeMessageOk,
  closeMessageWarning,
  closeMessageLoading,
} from "../modules/utils/actions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import amber from "@material-ui/core/colors/amber";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: "#a68540", //
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  loading: {},
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

class SnackBars extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  handleOnClose = () => {
    this.props.closeCallBack && this.props.closeCallBack();
    this.props.closeMessageOk();
  };

  render() {
    const { classes } = this.props;
    // console.log('messageOk')
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.props.messageOk}
          autoHideDuration={1300}
          onClose={this.handleOnClose}
        >
          <SnackbarContent
            className={classNames(classes.success, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <CheckCircleIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.props.messageOkStr}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.closeMessageOk}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.props.messageError}
          autoHideDuration={1300}
          onClose={this.props.closeMessageError}
        >
          <SnackbarContent
            className={classNames(classes.error, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.props.messageErrorStr}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.closeMessageError}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.props.messageWarning}
          autoHideDuration={1300}
          onClose={this.props.closeMessageWarning}
        >
          <SnackbarContent
            className={classNames(classes.warning, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <WarningIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.props.messageWarningStr}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.closeMessageWarning}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.props.messageInfo}
          autoHideDuration={1300}
          onClose={this.props.closeMessageInfo}
        >
          <SnackbarContent
            className={classNames(classes.info, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <InfoIcon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.props.messageInfoStr}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.closeMessageInfo}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.props.messageLoading}
          onClose={this.props.closeMessageLoading}
        >
          <SnackbarContent
            className={classNames(classes.info, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <CircularProgress
                  className={classNames(classes.icon, classes.iconVariant)}
                  color="inherit"
                  size={16}
                />
                {this.props.messageLoadingStr}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.closeMessageLoading}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}

SnackBars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    messageOk: state.utils.messageOk,
    messageOkStr: state.utils.messageOkStr,
    messageError: state.utils.messageError,
    messageErrorStr: state.utils.messageErrorStr,
    messageWarning: state.utils.messageWarning,
    messageWarningStr: state.utils.messageWarningStr,
    messageInfo: state.utils.messageInfo,
    messageInfoStr: state.utils.messageInfoStr,
    messageLoading: state.utils.messageLoading,
    messageLoadingStr: state.utils.messageLoadingStr,
    closeCallBack: state.utils.closeCallBack,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMessageOk: () => {
      dispatch(closeMessageOk());
    },
    closeMessageError: () => {
      dispatch(closeMessageError());
    },
    closeMessageWarning: () => {
      dispatch(closeMessageWarning());
    },
    closeMessageInfo: () => {
      dispatch(closeMessageInfo());
    },
    closeMessageLoading: () => {
      dispatch(closeMessageLoading());
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SnackBars),
);
