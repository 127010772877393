/**
 * 防抖函数 - debounce
 *
 * @param {Function} func - 需要防抖处理的目标函数。
 * @param {number} wait - 在再次触发前的等待毫秒数，默认为0，意味着不等待直接执行。
 * @param {boolean} [immediate=false] - 如果设置为true，则立即执行func，然后在wait时间后阻止下一次执行；
 *                                      如果是false，则在第一次触发时不执行func，而是在wait时间后执行。
 * @returns {Function} 返回一个新的函数，这个新函数会根据防抖逻辑来决定何时调用传入的`func`函数。
 */
export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    clearTimeout(timeout);

    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timeout = setTimeout(() => func.apply(context, args), wait);
    }
  };
}

export function throttle(fun, delay) {
  let last, deferTimer;
  return function (args) {
    let that = this;
    let _args = arguments;
    let now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fun.apply(that, _args);
      }, delay);
    } else {
      last = now;
      fun.apply(that, _args);
    }
  };
}
