import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import copy from "copy-to-clipboard/index";
import IconCopy from "@material-ui/icons/FileCopyOutlined";
import { messageOk } from "../modules/utils/actions";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import IconNetwork from "@material-ui/icons/SwapVert";
import IconMemory from "@material-ui/icons/Memory";
import IconStorage from "@material-ui/icons/SaveOutlined";
import IconHelp from "@material-ui/icons/HelpOutline";
import { fetchInfo } from "../modules/user/actions";

const styles = (theme) => ({
  card: {
    width: 180,
    display: "inline-block",
    margin: theme.spacing(2),
    textAlign: "center",
    // background: 'linear-gradient(45deg, #eee 30%, #ddd 93%)',
    // backgroundColor: '#cfae51',
    // color: '#fff',
    // marginLeft: 0,
  },
  media: {
    height: 185,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  gap: {
    height: theme.spacing(2),
  },
  slider: {
    padding: "22px 0px",
  },
  table: {
    display: "table",
  },
  tableRow: {
    display: "table-row",
    padding: "15px",
  },
  tableCell: {
    display: "table-cell",
    padding: "5px",
    verticalAlign: "middle",
    textAlign: "right",
  },
  tableCell1: {
    display: "table-cell",
    padding: "5px",
    verticalAlign: "middle",
    textAlign: "right",
    width: "100%",
  },
  code: {
    overflowX: "auto",
  },
  codeArea: {
    overflowX: "auto",
    padding: "10px",
    marginBottom: theme.spacing(1),
    backgroundColor: grey[800],
    border: "solid 1px",
    borderColor: grey[900],
    borderRadius: "5px",
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace',
    color: grey[300],
  },
  highlightSpan: {
    color: green["A700"],
  },
  overHidden: {
    // overflowX: 'hidden',
  },
  spanButton: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    display: "inline-block",
    color: blue[500],
  },
  aLink: {
    color: blue[500],
  },
  setModeButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  topItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class UserStore extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.copyText = this.copyText.bind(this);
    this.setUserID = this.setUserID.bind(this);
    this.handleSetMode = this.handleSetMode.bind(this);
    this.state = {
      storage: 100,
      bandwidth: 100,
      memory: 100,
      storageDirectory: "auto",
      platform: "PEAR_1900_X64_LINUX",
      cpu: "auto",
      tool: "curl",
      userID: localStorage.getItem("openfogos_userid"),
    };
  }

  copyText(text) {
    copy(text);
    this.props.messageOk("复制成功");
  }
  setUserID = (id) => () => {
    this.setState({
      userID: id,
    });
  };

  handleSliderChange = (name) => (event, value) => {
    // console.log("target", event.target);
    this.setState({
      [name]: value,
    });
  };
  handleTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleSetMode = (percent) => () => {
    this.setState({
      storage: percent,
      bandwidth: percent,
      memory: percent,
    });
  };
  componentDidMount() {
    this.props.fetchInfo();
  }

  render() {
    const { classes } = this.props;
    const modeIndex =
      this.state.storage === this.state.bandwidth &&
      this.state.storage === this.state.memory
        ? this.state.storage
        : 0;
    const cmdNew = `install_args="--platform=${this.state.platform} --user_id=${this.props.mobile || ""} --storage_path=${this.state.storageDirectory} --cpu_cores=${this.state.cpu} --storage_size=${this.state.storage < 100 ? this.state.storage + "%" : "auto"} --bandwidth=${this.state.bandwidth < 100 ? this.state.bandwidth + "%" : "auto"} --memory=${this.state.memory < 100 ? this.state.memory + "%" : "auto"}" bash -c "$(curl -sS https://download.openfogos.com/linux/openfog_installer.sh)"`;
    const cmd1 = (
      <div>
        install_args="--platform=
        <span className={classes.highlightSpan}>
          {this.state.platform}
        </span>{" "}
        --user_id=
        <span className={classes.highlightSpan}>
          {this.props.mobile || ""}
        </span>{" "}
        --storage_path=
        <span className={classes.highlightSpan}>
          {this.state.storageDirectory}
        </span>{" "}
        --cpu_cores=
        <span className={classes.highlightSpan}>{this.state.cpu}</span>{" "}
        --storage_size=
        <span className={classes.highlightSpan}>
          {this.state.storage < 100 ? this.state.storage + "%" : "auto"}
        </span>{" "}
        --bandwidth=
        <span className={classes.highlightSpan}>
          {this.state.bandwidth < 100 ? this.state.bandwidth + "%" : "auto"}
        </span>{" "}
        --memory=
        <span className={classes.highlightSpan}>
          {this.state.memory < 100 ? this.state.memory + "%" : "auto"}
        </span>
        " bash -c "$(curl -sS
        https://download.openfogos.com/linux/openfog_installer.sh)"
      </div>
    );
    return (
      <div>
        <div className={classes.topItem}>
          <Typography variant="h5" gutterBottom>
            x86_64的Linux系统
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom>
          自动生成批量安装并绑定到当前账户的命令。
        </Typography>
        <Typography variant="body1" gutterBottom>
          <span>
            设备将会绑定到当前登录账户：
            {localStorage.getItem("openfogos_username")}
            {/*<span onClick={this.setUserID(localStorage.getItem('openfogos_userid'))} className={classes.spanButton}>自动填入</span>*/}
          </span>
        </Typography>
        <Typography variant="body1">
          <span>
            存储路径（推荐用默认auto，程序会自动检测出剩余空间最大的分区；也可自定义存储目录，如/
            <Tooltip
              title="此时会在根目录/下新建一个子目录，用于共享存储"
              placement="top"
            >
              <IconHelp
                className={classes.verticalMiddle}
                color="action"
                style={{ fontSize: 16 }}
              />
            </Tooltip>
            ）：
          </span>
        </Typography>
        <Tooltip
          title="默认会在剩余空间最大的分区新建一个一级目录用于共享存储，也可以自定义"
          placement="top"
        >
          <TextField
            fullWidth
            value={this.state.storageDirectory}
            onChange={this.handleTextFieldChange("storageDirectory")}
            margin="normal"
          />
        </Tooltip>
        <Typography id="memory-label" variant="body1" gutterBottom>
          运行以下命令
          <IconCopy onClick={() => this.copyText(cmdNew)} color="action" />
        </Typography>
        <div className={classes.codeArea}>{cmd1}</div>
        <Typography align="center">
          <Button
            onClick={() => this.copyText(cmdNew)}
            color="primary"
            variant="contained"
          >
            复制命令
          </Button>
        </Typography>

        <Button
          onClick={this.handleSetMode(100)}
          color={modeIndex === 100 ? "primary" : "default"}
          variant={modeIndex === 100 ? "contained" : "outlined"}
          size="small"
          className={classes.setModeButton}
        >
          高收益模式
        </Button>
        <Button
          onClick={this.handleSetMode(80)}
          color={modeIndex === 80 ? "primary" : "default"}
          variant={modeIndex === 80 ? "contained" : "outlined"}
          size="small"
          className={classes.setModeButton}
        >
          保守模式
        </Button>
        <Button
          onClick={this.handleSetMode(60)}
          color={modeIndex === 60 ? "primary" : "default"}
          variant={modeIndex === 60 ? "contained" : "outlined"}
          size="small"
          className={classes.setModeButton}
        >
          低收益模式
        </Button>

        <Typography
          id="storage-label"
          variant="body1"
          className={classes.verticalMiddle}
        >
          <IconStorage className={classes.verticalMiddle} /> 分配存储：
          {this.state.storage === 100 ? "默认" : this.state.storage + "%"}
        </Typography>
        <Slider
          min={60}
          max={100}
          step={1}
          classes={{ container: classes.slider }}
          className={classes.overHidden}
          value={this.state.storage}
          aria-labelledby="storage-label"
          onChange={this.handleSliderChange("storage")}
        />
        <Typography
          id="bandwidth-label"
          variant="body1"
          className={classes.verticalMiddle}
        >
          <IconNetwork className={classes.verticalMiddle} /> 分配带宽：
          {this.state.bandwidth === 100 ? "默认" : this.state.bandwidth + "%"}
        </Typography>
        <Slider
          min={60}
          max={100}
          step={1}
          classes={{ container: classes.slider }}
          className={classes.overHidden}
          value={this.state.bandwidth}
          aria-labelledby="bandwidth-label"
          onChange={this.handleSliderChange("bandwidth")}
        />
        <Typography variant="body1" className={classes.verticalMiddle}>
          <embed
            className={classes.verticalMiddle}
            src="/memory.svg"
            type="image/svg+xml"
            width="24"
            height="24"
            style={{ marginRight: "0px" }}
          />{" "}
          分配内存：
          {this.state.memory === 100 ? "默认" : this.state.memory + "%"}
        </Typography>
        <Slider
          min={60}
          max={100}
          step={1}
          classes={{ container: classes.slider }}
          className={classes.overHidden}
          value={this.state.memory}
          onChange={this.handleSliderChange("memory")}
        />
        <Typography variant="body1" className={classes.verticalMiddle}>
          <IconMemory className={classes.verticalMiddle} /> CPU核数：
        </Typography>
        <FormControl margin="normal" fullWidth>
          <Select
            value={this.state.cpu}
            onChange={this.handleTextFieldChange("cpu")}
            inputProps={{
              name: "age",
              id: "age-simple",
            }}
          >
            <MenuItem value={"auto"}>auto</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={24}>24</MenuItem>
            <MenuItem value={32}>32</MenuItem>
            <MenuItem value={64}>64</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.topItem}>
          <Typography variant="h5" gutterBottom>
            智能路由器
          </Typography>
          <a
            className={classes.aLink}
            href={
              "https://github.com/OpenFog/guide/wiki/%E9%80%9A%E8%BF%87%E5%AE%89%E8%A3%85ipk%E5%8C%85%E5%8A%A0%E5%85%A5OpenFogOS%E6%BF%80%E5%8A%B1%E8%AE%A1%E5%88%92"
            }
            target={"_blank"}
          >
            教程
          </a>
        </div>
      </div>
    );
  }
}
UserStore.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  // console.log("mobile", state.user.info.mobile || "");
  return {
    mobile: state.user.info?.mobile || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    fetchInfo: () => {
      dispatch(fetchInfo());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(UserStore)));
