import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import SiteLayout from "./components/layout/SiteLayout";
import HomeLayout from "./components/layout/HomeLayout";
import NotFound from "./pages/NotFound";
import Site from "./pages/Site";
import LargeRecruit from "./pages/LargeRecruit";
import Login from "./pages/user/Login/Login";
import Home from "./pages/Home";
import HomeWindows from "./pages/HomeWindows";
import Advice from "./pages/advice/advice";
import MakeScript from "./pages/MakeScript";
import { view as Store } from "./modules/user/store";
import { view as Advanced } from "./modules/miner/list";
import { view as AdvancedBC } from "./modules/bc/list";
import { view as BindNode } from "./modules/node/bind";
import { view as Certification } from "./modules/user/certification";
import { view as SubmitNode } from "./modules/userMine/submitNode";
import { view as ModifyNode } from "./modules/userMine/modifyNode";
import { view as WithdrawLog } from "./modules/userMine/withdrawLog";
import Withdraw from "./pages/Withdraw";
import Goods1 from "./modules/user/store/goods1";
import Goods2 from "./modules/user/store/goods2";
import Link5 from "./pages/advice/firmware";
import MyReward from "./pages/user/MyReward";
import MyHistory from "./pages/user/MyHistory";
import MyBill from "./pages/user/MyBill";
import MyWithdraw from "./pages/user/MyWithdraw";
import MyBasicNodes from "./pages/user/MyBasicNodes";
import MyMineNodes from "./pages/user/MyMineNodes";
import MyAdvancedNodes from "./pages/user/MyAdvancedNodes";
import Help from "./pages/help/help";
import {
  bandwidth,
  basic_local,
  basic_remote,
  bind_node,
  bind_node_2,
  common_problem,
  difference,
  restore_mirror,
  restore_mirror_centos,
  restore_mirror_en,
  etcher_flash_img,
  extend_ubuntu_img,
  pearos_ubuntu_change_log,
  Hints,
  install_ubuntu,
  install_ubuntu_iso,
  make_system_drive,
  dg_image_restore,
  network_config,
  network_guide,
  paid_rule,
  PCDN,
  public_ip,
  rufus_install_iso,
  UPnP,
  flush_ax1800pro,
} from "./pages/markdown";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/home" component={HomeLayout}></Route>
      <Route path="/login" component={Login}></Route>
      <Route path="/" component={SiteLayout}></Route>
      <Route path="*" component={NotFound}></Route>
    </Switch>
  </BrowserRouter>
);

const SiteRoutes = () => (
  <Switch>
    {/*<Route path="/login" component={Login}></Route>*/}
    <Route path="/" component={LargeRecruit} exact></Route>
    <Route path="/#suggestion" component={LargeRecruit} exact></Route>
    <Route path="/mini" component={Site} exact></Route>
    <Route path="/large" component={LargeRecruit} exact></Route>
    <Route path="/*" component={NotFound}></Route>
  </Switch>
);

const HomeRoutes = () => (
  <Switch>
    <PrivateRoute path="/home/my_reward" component={MyReward}></PrivateRoute>
    <PrivateRoute path="/home/my_history" component={MyHistory}></PrivateRoute>
    <PrivateRoute path="/home/my_bill" component={MyBill}></PrivateRoute>
    <PrivateRoute
      path="/home/my_withdraw"
      component={MyWithdraw}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/my_basic_nodes"
      component={MyBasicNodes}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/my_mine_nodes"
      component={MyMineNodes}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/my_advanced_nodes"
      component={MyAdvancedNodes}
    ></PrivateRoute>
    <PrivateRoute path="/home/help" component={Help}></PrivateRoute>

    <PrivateRoute
      path="/home/help_basic_local"
      component={basic_local}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/help_basic_remote"
      component={basic_remote}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/help_difference"
      component={difference}
    ></PrivateRoute>
    <Route path="/home/help_rule" component={paid_rule}></Route>

    <PrivateRoute path="/home/index" component={LargeRecruit}></PrivateRoute>
    <PrivateRoute path="/home/mini_manage" component={Home}></PrivateRoute>
    <PrivateRoute
      path="/home/windows_manage"
      component={HomeWindows}
    ></PrivateRoute>
    <Route path="/home/large_recruit" component={LargeRecruit} exact></Route>

    {/*-------------------------------------Document-------------------------------------*/}
    <Route path="/home/doc/" component={make_system_drive} exact></Route>
    <Route
      path="/home/doc/make_system_drive"
      component={make_system_drive}
      exact
    ></Route>
    <Route
      path="/home/doc/dg_image_restore"
      component={dg_image_restore}
      exact
    ></Route>
    {/* TODO: 删掉老版本路由 makesystemdisk */}
    <Route
      path="/home/doc/makesystemdisk"
      component={make_system_drive}
      exact
    ></Route>
    <Route path="/home/doc/bind_node" component={bind_node} exact></Route>
    <Route path="/home/doc/bindnode" component={bind_node_2} exact></Route>

    <Route
      path="/home/doc/common_problem"
      component={common_problem}
      exact
    ></Route>
    <Route
      path="/home/doc/network_guide"
      component={network_guide}
      exact
    ></Route>
    <Route
      path="/home/doc/install_ubuntu_tuition"
      component={install_ubuntu}
      exact
    ></Route>
    <Route
      path="/home/doc/install_ubuntu_iso"
      component={install_ubuntu_iso}
      exact
    ></Route>
    <Route
      path="/home/doc/extend_ubuntu_img"
      component={extend_ubuntu_img}
      exact
    ></Route>
    <Route
      path="/home/doc/pearos_ubuntu_change_log"
      component={pearos_ubuntu_change_log}
      exact
    ></Route>
    <Route
      path="/home/doc/etcher_flash_img"
      component={etcher_flash_img}
      exact
    ></Route>
    <Route
      path="/home/doc/rufus_install_iso"
      component={rufus_install_iso}
      exact
    ></Route>
    <Route
      path="/home/doc/restore_mirror"
      component={restore_mirror}
      exact
    ></Route>
    <Route
      path="/home/doc/restore_mirror_en"
      component={restore_mirror_en}
      exact
    ></Route>
    <Route
      path="/home/doc/restore_mirror_centos"
      component={restore_mirror_centos}
      exact
    ></Route>
    <Route
      path="/home/doc/network_config"
      component={network_config}
      exact
    ></Route>
    <Route path="/home/doc/pcdn" component={PCDN} exact></Route>

    <Route path="/home/doc/supporteddevices" component={Link5} exact></Route>
    <Route
      path="/home/doc/flush_ax1800pro"
      component={flush_ax1800pro}
      exact
    ></Route>

    {/*<PrivateRoute path="/home/update" component={Update}></PrivateRoute>
        <PrivateRoute path="/home/node/bind" component={Bind}></PrivateRoute>
        <PrivateRoute path="/home/node/cache" component={Cache}></PrivateRoute>
        <PrivateRoute path="/home/node/detail" component={Detail}></PrivateRoute>
        <PrivateRoute path="/home/node/config" component={Config}></PrivateRoute>
        <PrivateRoute path="/home/node" component={Node}></PrivateRoute>*/}
    <PrivateRoute path="/home/withdraw" component={Withdraw}></PrivateRoute>
    <PrivateRoute path="/home/hints" component={Hints}></PrivateRoute>
    <PrivateRoute path="/home/advice" component={Advice} exact></PrivateRoute>
    <PrivateRoute
      path="/home/advice/bandwidth"
      component={bandwidth}
      exact
    ></PrivateRoute>
    <PrivateRoute
      path="/home/advice/upnp"
      component={UPnP}
      exact
    ></PrivateRoute>
    <PrivateRoute
      path="/home/advice/public_ip"
      component={public_ip}
      exact
    ></PrivateRoute>
    <PrivateRoute path="/home/script" component={MakeScript}></PrivateRoute>
    <PrivateRoute path="/home/store" exact component={Store}></PrivateRoute>
    <PrivateRoute
      path="/home/advanced"
      exact
      component={Advanced}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/advanced_bc"
      exact
      component={AdvancedBC}
    ></PrivateRoute>
    <PrivateRoute path="/home/bind" exact component={BindNode}></PrivateRoute>
    <PrivateRoute
      path="/home/certification"
      exact
      component={Certification}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/submit_node"
      exact
      component={SubmitNode}
    ></PrivateRoute>
    <PrivateRoute
      path="/home/modify_node"
      exact
      component={ModifyNode}
    ></PrivateRoute>
    {/*<PrivateRoute path="/home/large_manage" exact component={NodeManage}></PrivateRoute>*/}
    {/* <PrivateRoute
      path="/home/large_withdraw_log"
      exact
      component={WithdrawLog}
    ></PrivateRoute> */}

    <PrivateRoute path="/home/store/goods1" component={Goods1}></PrivateRoute>
    <PrivateRoute path="/home/store/goods2" component={Goods2}></PrivateRoute>
    <PrivateRoute path="/home" component={LargeRecruit}></PrivateRoute>
  </Switch>
);

export { Routes, SiteRoutes, HomeRoutes };
