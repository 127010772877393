import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles/index";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RightArrowIcon from "@material-ui/icons/NavigateNext";

const styles = (theme) => ({
  root: {
    // background: "#fff",
    marginTop: 2,
    marginBottom: 2,
  },
  abstract: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: ".2rem 1rem",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  titleContent: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  total: {
    position: "absolute",
    bottom: "50%",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  detail: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  detailItem: {
    padding: theme.spacing(1),
  },
  title: {
    padding: theme.spacing(1),
  },
  thinContainer: {
    position: "relative",
    width: "100%",
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `#fff`,
    margin: "2px 0",
    cursor: "pointer",
  },
  icon: {
    verticalAlign: "middle",
    marginRight: -theme.spacing(1),
  },
});

/**
 *   title: "通知",
 *   abstractColor: "#f00"
 *   abstract: "九月份是囧代分销三等分砍掉啊道街，发的赛快餐広。翻到从来光扫到草桑代非常"
 */
function DisplayAssets(props) {
  const { classes, title, abstractColor, abstract, children } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.thinContainer}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        wrap={"nowrap"}
        onClick={handleClickOpen}
      >
        <Typography
          variant="subtitle1"
          style={{ color: abstractColor }}
          className={classes.titleContent}
        >
          {abstract}
        </Typography>
        <RightArrowIcon className={classes.icon} color="action" />
      </Grid>
      {/*<Typography display={"inline"} variant={"body1"} className={classes.abstract}  display={"block"} onClick={handleClickOpen}><span style={{color: abstractColor}}>{title}</span>：{abstract}</Typography>*/}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {/*<DialogContentText>*/}
          {children}
          {/*</DialogContentText>*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(withRouter(DisplayAssets));
