import React from "react";
//import { view as UserInfo } from '../modules/user/info/'
import { view as UserToken } from "../modules/user/token/";
import { view as NodeList } from "../modules/node/list/";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bcGet, minerGet } from "../modules/auth/actions";

const styles = (theme) => ({
  gap: {
    height: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class Home extends React.Component {
  render() {
    const { classes } = this.props;
    // console.log("is miner:", this.props.isMiner);

    return (
      <div className="Home">
        <UserToken />
        <div className={classes.gap}></div>

        {/*<Invite/>*/}
        <div className={classes.gap}></div>
        <NodeList />
      </div>
    );
  }
  componentDidMount() {
    this.props.onRequire();
  }
}
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

Home.defaultProps = {
  isMiner: false,
};

const mapStateToProps = (state) => {
  return {
    isMiner: state.auth.isMiner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(minerGet());
      dispatch(bcGet());
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
