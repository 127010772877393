import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { debounce, throttle } from "../utils/tools";

export const useThrottle = (callback, limit) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const throttledCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return throttle(func, limit);
  }, []);

  return throttledCallback;
};

export const useDebounce = (callback, limit) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, limit);
  }, []);

  return debouncedCallback;
};
