import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Blue from "@material-ui/core/colors/blue";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
  floatRight: {
    float: "right",
  },
  aLink: {
    color: Blue[500],
  },
  whiteText: {
    color: "#000",
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
}));

const Contact = () => {
  const classes = useStyles();
  // console.log('window.location :', window.location);
  const href = window.location.href;
  const keysForMini = ["mini", "store", "hints", "script"];
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            display={"block"}
            variant="caption"
            className={classes.whiteText}
          >
            相关链接
          </Typography>
          {keysForMini.some((key) => href.indexOf(key) >= 0)
            ? [
                <Typography
                  key={1}
                  display={"block"}
                  variant="caption"
                  color="textSecondary"
                >
                  <Link
                    className={classes.aLink}
                    to={"/home/doc/bindnode"}
                    target={"_self"}
                  >
                    如何绑定闲置小节点
                  </Link>
                </Typography>,
                <Typography
                  key={2}
                  display={"block"}
                  variant="caption"
                  color="textSecondary"
                >
                  <Link
                    className={classes.aLink}
                    to={"/home/doc/supporteddevices"}
                    target={"_self"}
                  >
                    已适配设备列表
                  </Link>
                </Typography>,
              ]
            : [
                //                             <Typography key={1} display={"block"} variant="caption" color='textSecondary'>
                //                                     <a className={classes.aLink} href="https://item.taobao.com/item.htm?id=613751308949
                // " target="_blank" rel="noopener noreferrer">购买系统盘</a>
                //                                 </Typography>,
                <Typography
                  key={1}
                  display={"block"}
                  variant="caption"
                  color="textSecondary"
                >
                  <Link
                    className={classes.aLink}
                    to={"/home/doc/make_system_drive"}
                    target={"_self"}
                  >
                    系统盘制作教程
                  </Link>
                </Typography>,
                <Typography
                  key={2}
                  display={"block"}
                  variant="caption"
                  color="textSecondary"
                >
                  <Link
                    className={classes.aLink}
                    to={"/home/doc/network_guide"}
                    target={"_self"}
                  >
                    Ubuntu 版系统网络配置教程
                  </Link>
                </Typography>,
                <Typography
                  key={3}
                  display={"block"}
                  variant="caption"
                  color="textSecondary"
                >
                  <Link
                    className={classes.aLink}
                    to={"/home/doc/flush_ax1800pro"}
                    target={"_self"}
                  >
                    京东云无线宝亚瑟 AX1800 Pro 刷机教程
                  </Link>
                </Typography>,
                // <Typography key={4} display={"block"} variant="caption" color='textSecondary'>
                //     <markdown className={classes.aLink} to={"/home/doc/pcdn"} target={"_self"}>Windows版PCDN安装教程</markdown>
                // </Typography>
              ]}
          {/*<Typography variant="caption" color='textSecondary'>
                        <a className={classes.aLink} href={"https://blog.csdn.net/weixin_44388511/article/details/86687616"} target={"_blank"}>常见问题</a>
                    </Typography>*/}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.floatRight}>
            <Typography
              display={"block"}
              variant="caption"
              align="left"
              className={classes.whiteText}
            >
              联系我们
            </Typography>
            <Typography
              display={"block"}
              variant="caption"
              color="textSecondary"
              align="left"
              className={classes.whiteText}
            >
              微信&emsp;&ensp;Cheedoong
            </Typography>
            {/*<Typography display={"block"} variant="caption" color='textSecondary' align='left' className={classes.whiteText}>
                            QQ群&emsp;{keysForMini.some(key => href.indexOf(key) >= 0) ? "715017630" : "852700383"}
                        </Typography>*/}
            <Typography
              display={"block"}
              variant="caption"
              color="textSecondary"
              align="left"
              className={classes.whiteText}
            >
              QQ群&emsp;715017630
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
