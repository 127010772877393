import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from "@material-ui/icons/NavigateNext";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: 66,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `#fff`,
    margin: "2px 0",
    cursor: "pointer",
  },
  historyContainer: {
    position: "relative",
    width: "100%",
    height: 66,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `#fff`,
    margin: "2px 0",
  },
  darkGap: {
    height: "2px",
    background: "#dedede",
  },
  cursorAuto: {
    cursor: "auto",
  },
  thinContainer: {
    position: "relative",
    width: "100%",
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `#fff`,
    margin: "2px 0",
    cursor: "pointer",
  },
  nodeInfoContainer: {
    position: "relative",
    width: "100%",
    minHeight: 36,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: `#fff`,
  },
  nodeInfoContent: {
    paddingRight: theme.spacing(1),
    "&:last-child": {
      paddingRight: 0,
    },
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: "1px",
    background: "#f7f7f7",
  },
  caption: {
    color: theme.myColor.grey,
    lineHeight: 1,
  },
  online: {
    color: theme.myColor.green,
  },
  offline: {
    color: theme.myColor.red,
  },
  warning: {
    color: theme.myColor.red,
  },
  icon: {
    verticalAlign: "middle",
    marginRight: -theme.spacing(1),
  },
  statusIcon: {
    verticalAlign: "middle",
    fontSize: ".8rem",
    marginRight: theme.spacing(0.5),
  },
  helpIcon: {
    fontSize: "1rem",
    color: theme.myColor.grey,
    position: "relative",
    top: "2px",
    left: "2px",
  },
}));

function RewardListItem(props) {
  const { name, online, offline, amount, to } = props;
  const classes = useStyles();

  return (
    <Link to={to}>
      <Grid
        className={classes.container}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid
          item
          xs
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              在线：
              <span className={online ? classes.online : ""}>
                {online}
              </span>{" "}
              离线：
              <span className={offline ? classes.offline : ""}>{offline}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            align={"right"}
            display={"inline"}
            style={{ verticalAlign: "middle" }}
          >
            +{amount.toFixed(2)}
          </Typography>
          <RightArrowIcon className={classes.icon} color="action" />
        </Grid>
      </Grid>
    </Link>
  );
}

function RewardHistoryListItem(props) {
  const { title, timestamp, amount } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.historyContainer}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.caption}>
            {moment(timestamp * 1000)
              .subtract(1, "d")
              .format("YYYY/MM/DD")}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        align={"right"}
        display={"inline"}
        style={{ verticalAlign: "middle" }}
      >
        +{amount.toFixed(2)}
      </Typography>
    </Grid>
  );
}

function BillHistoryListItem(props) {
  const { title, timestamp, amount } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.historyContainer}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.caption}>
            {moment(timestamp * 1000).format("YYYY/MM/DD HH:mm:ss")}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        align={"right"}
        display={"inline"}
        style={{ verticalAlign: "middle" }}
        className={amount < 0 ? classes.warning : ""}
      >
        {amount > 0 ? "+" : ""}
        {amount.toFixed(2)}
      </Typography>
    </Grid>
  );
}

function WithdrawListItem(props) {
  const { total, tax, actual, status, date, onClick } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={onClick}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{total.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.caption}>
            到账：<span className={classes.online}>{actual.toFixed(2)}</span>{" "}
            代缴税：{tax.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "right",
          }}
        >
          <Typography
            variant="subtitle1"
            align={"right"}
            display={"inline"}
            style={{ verticalAlign: "middle" }}
          >
            {status}
          </Typography>
          <Typography
            display="block"
            variant="caption"
            className={classes.caption}
          >
            {moment(date).format("YYYY/MM/DD")}
          </Typography>
        </div>
        {onClick && <RightArrowIcon className={classes.icon} color="action" />}
      </Grid>
    </Grid>
  );
}

function BasicNodeListItem(props) {
  const { nodeInfo, ...other } = props;
  const {
    mac,
    nickname,
    online,
    approved,
    onlineTime,
    rewardYesterday,
    moreReward,
  } = nodeInfo;
  const classes = useStyles();
  let dotColor = classes.online;
  if (!online) {
    dotColor = classes.offline;
  }
  if (!approved) {
    dotColor = classes.caption;
  }

  return (
    <Grid
      className={classes.container}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      {...other}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {nickname || "未命名"}
            <Typography variant="caption" className={classes.caption}>
              （昨日在线
              <span className={classes.online}>
                {(onlineTime || 0).toFixed(2)}
              </span>
              h）
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DotIcon className={`${classes.statusIcon} ${dotColor}`} />
          <Typography variant="caption" className={classes.caption}>
            {mac}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "right",
          }}
        >
          <Typography
            variant="subtitle1"
            align={"right"}
            display={"inline"}
            style={{ verticalAlign: "middle" }}
          >
            +{rewardYesterday.toFixed(2)}
          </Typography>
          <Typography
            display="block"
            variant="caption"
            className={classes.caption}
          >
            待提升：
            <span className={classes.warning}>
              {(moreReward || 0).toFixed(2)}
            </span>
          </Typography>
        </div>
        <RightArrowIcon className={classes.icon} color="action" />
      </Grid>
    </Grid>
  );
}

function InviteeListItem(props) {
  const { nodeInfo, ...other } = props;
  const { userName, nodes, rewardYesterday, totalReward } = nodeInfo;
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.container, classes.cursorAuto)}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      {...other}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{userName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.caption}>
            节点数：<span className={classes.online}>{nodes || 0}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "right",
          }}
        >
          <Typography
            variant="subtitle1"
            align={"right"}
            display={"inline"}
            style={{ verticalAlign: "middle" }}
          >
            +{rewardYesterday.toFixed(2)}
          </Typography>
          {/*<Typography display="block" variant="caption" className={classes.caption}>总推广收益：<span className={classes.warning}>{(totalReward||0).toFixed(2)}</span></Typography>*/}
        </div>
      </Grid>
    </Grid>
  );
}

function MineNodeListItem(props) {
  const { nodeInfo, ...other } = props;
  const {
    pearID,
    node_id,
    ip,
    nickname,
    online,
    approved,
    onlineTime,
    rewardYesterday,
    totalReward,
    waiting,
  } = nodeInfo;
  const classes = useStyles();
  let dotColor = classes.online;
  if (!online) {
    dotColor = classes.offline;
  }
  if (!approved) {
    dotColor = classes.caption;
  }

  return (
    <React.Fragment>
      <Grid
        className={classes.container}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        {...other}
      >
        <Grid
          item
          xs
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {nickname || "未命名"}
              <Typography variant="caption" className={classes.caption}>
                （昨日在线
                <span className={classes.online}>
                  {(onlineTime || 0).toFixed(2)}
                </span>
                h）
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DotIcon className={`${classes.statusIcon} ${dotColor}`} />
            <Typography variant="caption" className={classes.caption}>
              {node_id}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              textAlign: "right",
            }}
          >
            <Typography
              variant="subtitle1"
              align={"right"}
              display={"inline"}
              style={{ verticalAlign: "middle" }}
            >
              {waiting ? `结算中` : `+${rewardYesterday.toFixed(2)}`}
            </Typography>
            <Typography
              display="block"
              variant="caption"
              className={classes.caption}
            >
              总收益：
              <span className={classes.warning}>
                {(totalReward || 0).toFixed(2)}
              </span>
            </Typography>
          </div>
          <RightArrowIcon className={classes.icon} color="action" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function AdvancedNodeListItem(props) {
  const { nodeInfo, ...other } = props;
  const { name, online, approved, rewardYesterday, totalReward, waiting } =
    nodeInfo;
  const classes = useStyles();
  let dotColor = classes.online;
  let statusStr = "在线";
  if (!online) {
    dotColor = classes.offline;
    statusStr = "离线";
  }
  if (!approved) {
    dotColor = classes.caption;
    statusStr = "未验收";
  }

  return (
    <Grid
      className={classes.container}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      {...other}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{name || "未命名"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DotIcon className={`${classes.statusIcon} ${dotColor}`} />
          <Typography variant="caption" className={classes.caption}>
            {statusStr}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "right",
          }}
        >
          <Typography
            variant="subtitle1"
            align={"right"}
            display={"inline"}
            style={{ verticalAlign: "middle" }}
          >
            {waiting ? `结算中` : `+${rewardYesterday.toFixed(2)}`}
          </Typography>
          <Typography
            display="block"
            variant="caption"
            className={classes.caption}
          >
            总收益：
            <span className={classes.warning}>
              {(totalReward || 0).toFixed(2)}
            </span>
          </Typography>
        </div>
        <RightArrowIcon className={classes.icon} color="action" />
      </Grid>
    </Grid>
  );
}

function LinkListItem(props) {
  const { title, subtitle, to, useHref, ...other } = props;
  const classes = useStyles();
  let Component = useHref ? "a" : Link;

  return (
    <Component to={to} {...other}>
      <Grid
        className={classes.container}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid
          item
          xs
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <RightArrowIcon className={classes.icon} color="action" />
        </Grid>
      </Grid>
    </Component>
  );
}

function SimpleLinkListItem(props) {
  const { title, to, useHref, ...other } = props;
  const classes = useStyles();
  let Component = useHref ? "a" : Link;

  return (
    <Component to={to} {...other}>
      <Grid
        className={classes.thinContainer}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid
          item
          xs
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item>
          <RightArrowIcon className={classes.icon} color="action" />
        </Grid>
      </Grid>
    </Component>
  );
}

function ActionListItem(props) {
  const { title, subtitle, onClick } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={onClick}
    >
      <Grid
        item
        xs
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.caption}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function SimpleActionListItem(props) {
  const { title, onClick, color = "textPrimary" } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.thinContainer}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={onClick}
    >
      <Typography variant="subtitle1" color={color}>
        {title}
      </Typography>
    </Grid>
  );
}

function BasicNodeInfoListItem(props) {
  const { label, content, comment } = props;
  const classes = useStyles();

  return (
    <div>
      <Grid
        className={classes.nodeInfoContainer}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={3} className={classes.nodeInfoContent}>
          <Typography variant="caption" className={classes.caption}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.nodeInfoContent}>
          <Typography variant="caption" className={classes.caption}>
            {content}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.nodeInfoContent}>
          <Typography variant="caption" className={classes.caption}>
            {comment}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.divider}></div>
    </div>
  );
}

function MineNodeInfoListItem(props) {
  const { label, content } = props;
  const classes = useStyles();
  return (
    <div>
      <Grid
        className={classes.nodeInfoContainer}
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6} className={classes.nodeInfoContent}>
          <Typography variant="caption" className={classes.caption}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.nodeInfoContent}>
          <Typography variant="caption" className={classes.caption}>
            {content}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.divider}></div>
    </div>
  );
}
function ErrorInfoListItem(props) {
  const { startTime, endTime, label, content, level, tips } = props;
  const classes = useStyles();
  const formatStr = "YYYY-MM-DD HH:mm:ss";
  const start = moment(startTime).format(formatStr);
  const end =
    moment(endTime).year() === 9999
      ? "持续中"
      : moment(endTime).format(formatStr);
  return (
    <Tooltip title={tips} arrow>
      <div>
        <Grid
          className={classes.nodeInfoContainer}
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={4} className={classes.nodeInfoContent}>
            <Typography variant="caption" className={classes.caption}>
              {start}
            </Typography>
            <br />
            <Typography variant="caption" className={classes.caption}>
              {end}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.nodeInfoContent}>
            <Typography variant="caption" className={classes.caption}>
              {label}
            </Typography>
            <HelpIcon className={classes.helpIcon} />
          </Grid>
          <Grid item xs={4} className={classes.nodeInfoContent}>
            <Typography variant="caption" className={classes.caption}>
              {content}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.divider}></div>
      </div>
    </Tooltip>
  );
}

export {
  ErrorInfoListItem,
  RewardListItem,
  WithdrawListItem,
  BasicNodeListItem,
  LinkListItem,
  ActionListItem,
  SimpleActionListItem,
  InviteeListItem,
  MineNodeListItem,
  AdvancedNodeListItem,
  SimpleLinkListItem,
  BasicNodeInfoListItem,
  MineNodeInfoListItem,
  RewardHistoryListItem,
  BillHistoryListItem,
};
