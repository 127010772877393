import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from "prop-types";
import { messageError, messageOk } from "../../../modules/utils/actions";
import { connect } from "react-redux";
import { reducerStatus } from "../../../modules/user/reducer";
import {
  fetchCertificationStatus,
  submitCertification,
} from "../../../modules/user/actions";
import TextField from "@material-ui/core/TextField";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing( 2),
  },
  button: {
    width: "100%",
    size: "medium",
  },
  input: {
    width: "300px",
    margin: theme.spacing(1),
  },
  inputDiv: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "300px",
    margin: theme.spacing(1),

    "& span": {
      marginLeft: theme.spacing(1),
      textAlign: "left",
      flexGrow: 1,
      flexShrink: 1,
      overFlow: "visible",
      whiteSpace: "nowrap",
    },
  },
  leftIcon: {
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  form: {
    textAlign: "center",
  },
});

const certificationStatusCaption = {
  0: "提交实名认证",
  1: "实名认证通过",
  2: "实名认证审核中",
  3: "实名认证失败，请重新提交",
};

class Certification extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onTextChange = this.onTextChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.state = {
      formData: {},
    };
  }

  onTextChange(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
    });
  }

  onFileChange(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.files[0],
        [event.target.name + "_value"]: event.target.value,
      },
    });
  }

  checkForm() {
    let formData = this.state.formData;
    if (
      !formData.name ||
      !formData.address ||
      !formData.card_number ||
      !formData.card_1 ||
      !formData.card_2
    ) {
      // console.log("checkForm false");
      return false;
    }
    // console.log("checkForm true");
    return true;
  }

  render() {
    const { classes, certificationStatusStatus } = this.props;
    const textFieldProps = {
      className: classes.input,
      onChange: this.onTextChange,
    };
    const canSubmit = this.checkForm();

    return (
      <form className={classes.form}>
        <TextField
          {...textFieldProps}
          required
          label="真实姓名"
          name="name"
          value={this.state.formData.name || ""}
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="地址"
          name="address"
          value={this.state.formData.address || ""}
        />
        <br />
        <TextField
          {...textFieldProps}
          required
          label="身份证号码"
          name="card_number"
          value={this.state.formData.card_number || ""}
        />
        <br />

        <div className={classes.inputDiv}>
          <label htmlFor="card_1_input">
            <Button color="default" variant={"outlined"} component="span">
              <UploadIcon className={classes.leftIcon} />
              身份证人像面
            </Button>
          </label>
          <span>{this.state.formData.card_1_value || "未选择文件"}</span>
          <input
            id="card_1_input"
            name="card_1"
            {...textFieldProps}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={this.onFileChange}
          />
        </div>
        <br />
        <div className={classes.inputDiv}>
          <label htmlFor="card_2_input">
            <Button color="default" variant={"outlined"} component="span">
              <UploadIcon className={classes.leftIcon} />
              身份证国徽面
            </Button>
          </label>
          <span>{this.state.formData.card_2_value || "未选择文件"}</span>
          <input
            id="card_2_input"
            name="card_2"
            {...textFieldProps}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={this.onFileChange}
          />
        </div>
        <br />
        <Button
          className={classes.input}
          color="primary"
          variant={"contained"}
          component="span"
          disabled={!canSubmit}
          onClick={() => this.props.onSubmit(this.state.formData)}
        >
          提交
        </Button>
      </form>
    );
  }
}

Certification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    certificationStatusStatus:
      state.user.certificationStatusStatus || reducerStatus.LOADING,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    onSubmit: (data) => {
      var formData = new FormData();
      formData.append("name", data.name);
      formData.append("address", data.address);
      formData.append("card_number", data.card_number);
      formData.append("card_1", data.card_1);
      formData.append("card_2", data.card_2);
      dispatch(
        submitCertification({
          data: formData,
          success: () => {
            dispatch(messageOk("提交成功"));
            dispatch(fetchCertificationStatus());
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Certification));
