import React from "react";
import { connect } from "react-redux";
import { submitBind } from "../../../modules/node/actions";
import LoginForm from "./bindForm";
import { messageError, messageOk } from "../../../modules/utils/actions";

class LoginBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      formData: {},
    };
  }
  onFormChange(fields) {
    this.setState({
      formData: {
        ...this.state.formData,
        ...fields,
      },
    });
  }
  onSubmit() {
    const matchMAC = (this.state.formData.mac || "")
      .toLowerCase()
      .trim()
      .match(/([0-9a-f]{2}:){5}[0-9a-f]{2}/);
    const matchPearID = (this.state.formData.pearid || "")
      .trim()
      .match(/.{2}-.{4}-.{12}-.{2}-.{8}/);
    this.props.onSubmit({
      mac: matchMAC ? matchMAC[0] : "",
      pearid: matchPearID ? matchPearID[0] : "",
    });
  }
  getArgs() {
    let args = {},
      query = this.props.location.search,
      search = decodeURIComponent(query.substring(1)),
      reg = /(?:([^&]+)=([^&]+))/g,
      match = reg.exec(search);
    while (match) {
      args[match[1]] = match[2];
      match = reg.exec(search);
    }
    return args;
  }
  render() {
    // console.log("render main formData", this.state.formData);
    const loginFormParam = {
      onSubmit: this.onSubmit,
      onChange: (fields) => this.onFormChange(fields),
      loading: this.props.loginLoading,
      ...this.state.formData,
    };
    return <LoginForm {...loginFormParam} />;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loginLoading: state.auth.loginStatus === "loading",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(
        submitBind({
          data: data,
          error: () => {
            dispatch(messageError("绑定失败！请检查输入是否有误。"));
          },
          success: () => {
            dispatch(messageOk("绑定成功！"));
          },
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginBox);
